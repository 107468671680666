import request from '@/utils/request'

// 查询Banner列表
export function findBanners(params) {
  return request({
    url: `/banners`,
    method: 'get',
    params: params
  })
}

// 查询排序列表
export function findSortBanners(params) {
  return request({
    url: `/banners/sort`,
    method: 'get',
    params: params
  })
}

// 更新Banner
export function updateBanner(id, data) {
  return request({
    url: `/banners/${id}`,
    method: 'put',
    data
  })
}

// 创建Banner
export function createBanner(data) {
  return request({
    url: `/banners`,
    method: 'post',
    data
  })
}

// 删除Banner
export function deleteBanner(id) {
  return request({
    url: `/banners/${id}`,
    method: 'delete'
  })
}

// 更新Banner有效性
export function updateBannerEffective(id, data) {
  return request({
    url: `/banners/${id}/effective`,
    method: 'patch',
    data
  })
}

