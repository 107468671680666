<template>
  <div>
    <search-banner @submit="submitSearch" ref="searchForm" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showSortModal">排序</a-button>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="images" slot-scope="imgs,record">
        <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">
          <img :src="imgs[0].url" style="height: 50px;width: 100px">
        </span>
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="deleteBanner(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增Banner模态框 -->
    <new-banner
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑Banner模态框 -->
    <edit-banner
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

    <sort
      v-if="isShowSortModal"
      :visible.sync="isShowSortModal"
      :data="sortData"
      table-name="banners"
      name-title="排序"
      @completed="fetchSortData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchBanner from '@/views/banner/Search'
import PreviewImage from '@/components/PreviewImage'
import { deleteBanner, findBanners, findSortBanners, updateBannerEffective } from '@/api/banner'

export default {
  name: 'SystemPackageList',
  components: {
    Pagination,
    PreviewImage,
    SearchBanner,
    Sort: () => import('@/components/Sort'),
    NewBanner: () => import('@/views/banner/New'),
    EditBanner: () => import('@/views/banner/Edit')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      previewVisible: false,
      previewImages: [],
      pagination: {
        total_count: 0
      },
      isShowSortModal: false,
      sortData: []
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: 'Banner名称',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '链接路径',
          dataIndex: 'link_url',
          width: 200
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    showSortModal() {
      findSortBanners().then((res) => {
        this.sortData = res.data
        this.isShowSortModal = true
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchSortData() {
      this.query = {}
      this.$refs.searchForm.clearForm()
      this.loading = true
      findBanners().then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    fetchData() {
      this.loading = true
      findBanners(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      vm.editingEffectiveId = record.id
      updateBannerEffective(record.id, { effective: effective })
        .then((res) => {
          vm.editingEffectiveId = 0
          if (res.code === 0) {
            record.effective = checked
          }
        })
        .catch(() => {
          vm.editingEffectiveId = 0
        })
    },

    deleteBanner(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？删除后无法恢复',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteBanner(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
